.portfolio__container {
    display:grid;
    grid-template-columns: repeat(3,1fr);
    gap:2.5rem;
}

.portfolio__items {
    background: var(--color-bg-variant);
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.portfolio__items:hover {
border-color: var(--color-bg-variant);
background: transparent;
}

.portfolio__item-image {
border-radius: 1.5rem;
overflow: hidden;
}

/* .img__description {
position:absolute;
left:0;
bottom: 0;
  right: 0;
  background: rgba(29, 106, 154, 0.72);
  color: #fff;
  visibility: hidden;
  opacity: 0;

  /* transition effect. not necessary */
  /* transition: opacity .2s, visibility .2s;
} */

/* .portfolio__item-image:hover .img__description {
    visibility: visible;
    opacity: 1;
  } */ */


.portfolio__item h3 {
margin: 1.2rem 0 2rem;
}

.portfolio__item-cta {
display:flex;
gap:1rem;
margin-bottom: 1rem;
}

/* ******* MEDIA QUERIES(MEDIUM DEVICES) *** */
@media screen and (max-width: 1024px) {
    .portfolio__container { /* when it gets smaller , make all child blocks to this class in to a column*/
        grid-template-columns: 1fr 1fr; 
        gap:1.2rem;
    }
    
    }
    
    /* *******MEDIA QUERIES (SMALL DEVICES **** Once it hits this width.. make it a portfolio container.. */
    @media screen and (max-width: 600px) {
        .portfolio__container {
            grid-template-columns: 1fr;
            gap:1rem;
        }
    
    
    
    
    
    }
