@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap);
/*Css reset */
* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0; 
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}
/*To reduce value redundancy   */
:root {
--color-bg: linear-gradient(#191919, transparent),
linear-gradient(to top left,#0c0c33 , transparent),
linear-gradient(to top right, rgb(32, 32, 51), transparent);
--color-bg-variant: #2c2c6c;
--color-primary: #2445a8;
--color-primary-variant: rgba(77,181,255,0.4);
--color-white: #fff;
--color-light: rgba(255,255,255,0.6);


--transition: all 400ms ease;

--container-width-lg:75%;
--container-width-md:86%;
--container-width-sm: 90%;
}


html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display:none;
}

body {
    font-family: 'Poppins', sans-serif;
    background: linear-gradient(#191919, transparent),
linear-gradient(to top left,#0c0c33 , transparent),
linear-gradient(to top right, rgb(32, 32, 51), transparent);
    background: var(--color-bg);
    color: #fff;
    color: var(--color-white);
    line-height: 1.7;

}





/* ============General STYLES===============*/
.container {
    width: 75%;
    width: var(--container-width-lg);
    margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5 {
    font-weight: 500;
}

section {
    margin-top:8rem;
}



h1 {
    font-size: 2.5em;
}

section > h2, section > h5 {
text-align: center;
color: rgba(255,255,255,0.6);
color: var(--color-light);

}

section > h2 {
    color: #2445a8;
    color: var(--color-primary);
    margin-bottom: 3rem;
}


.text-light {
  color: rgba(255,255,255,0.6);
  color: var(--color-light);
}

a {
color: #2445a8;
color: var(--color-primary);
transition:all 400ms ease;
transition:var(--transition)
}

a:hover {
    color: #fff;
    color: var(--color-white);
}

.btn {
    width: -webkit-max-content;
    width: max-content;
    display: inline-block;  
    color: #2445a8;  
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor:pointer;
    border: 1px solid #2445a8;
    border: 1px solid var(--color-primary);
    transition: all 400ms ease;
    transition: var(--transition);

}

.btn:hover {
background: #2c2c6c;
background: var(--color-bg-variant);
color: linear-gradient(#191919, transparent),
linear-gradient(to top left,#0c0c33 , transparent),
linear-gradient(to top right, rgb(32, 32, 51), transparent);
color: var(--color-bg);
border-color: transparent;
}

.cta > .btn:hover {
    background: #2c2c6c;
    background: var(--color-bg-variant);
    color: linear-gradient(#191919, transparent),
linear-gradient(to top left,#0c0c33 , transparent),
linear-gradient(to top right, rgb(32, 32, 51), transparent);
    color: var(--color-bg);
    border-color: transparent;
    }

.btn-primary {
background: #2445a8;
background: var(--color-primary);
color: linear-gradient(#191919, transparent),
linear-gradient(to top left,#0c0c33 , transparent),
linear-gradient(to top right, rgb(32, 32, 51), transparent);
color: var(--color-bg);

}

img {
display: block;
width:100%;
object-fit: cover;
}

/* ================= MEDIA QUERIES(MEDIUM QUERIES)======  */
@media screen and (max-width:1024px) {
    .container {
        width: 86%;
        width: var(--container-width-md);
    }

    section > h2 {
         margin-top: 6em
    }

}


/* ================= MEDIA QUERIES(SMALLL QUERIES)======  */
@media screen and (max-width:600px) {
    .container {
        width: 90%;
        width: var(--container-width-sm);
    }

    section > h2 {
         margin-bottom: 2rem
    }








}












/* outline --- area immediately outside border of content

The max-content sizing keyword represents the intrinsic maximum width 
or height of the content.
 For text content this means that the
 content will not wrap at all even if it causes overflows.

 display: inline-block;  
 the block doesnt start a new line horizontally..

 object-fit is a property that sets how content of a replaced element such as 
 img should be resized to fit in its container..


*/
header {
    height: 90vh;
    padding-top:7rem;
    overflow:hidden;

}

.header__container {
text-align: center;
height: 100%;
position: relative;

}


/* ===========CTA(button cv =========== */
.cta {
    margin-top:2.5rem;
    display:flex;
    grid-gap:1.2rem;
    gap:1.2rem;
    justify-content:center;
}
/*==== Header Socials icons. */
.header__socials {
display: flex;
flex-direction: column;
align-items: center;
grid-gap:0.8rem;
gap:0.8rem;
position: absolute;
left:0;
bottom:3rem;
}

.header__socials:after {
content: '';
width: 1px;
height:2rem;
background: var(--color-primary);
}
/*The following example inserts the value of ''  in 
parenthesis after each header__socials element:                              */
/* Me==========================================*/

.me {
display:flex;
justify-content: center;
background: linear-gradient(var(--color-primary),transparent);
width: 22rem;
height:30rem;
left: calc(50% - 11rem);
margin-top: 4rem;
border-radius: 12rem 12rem 0 0;
overflow: hidden;
padding: 5rem 1.5rem 1.5rem 1.5rem;
}

/* ====== Scroll Dowm ==== */

.scroll__down {
position: absolute;
right: -2.3rem;
bottom: 5rem;
-webkit-transform: rotate(90deg);
        transform: rotate(90deg);
font-weight: 300;
font-size: 0.9rem;

}

/* ===MEDIA QUERIES ============================ */
@media screen and (max-width: 1024px) {
header {
    height: 70vh;
}


}


/* ===MEDIA QUERIES ============================(SMALL DEVICES */
@media screen and (max-width: 600px) {
  header {

    height: 70vh;

  }

  .header__socials,
  .scroll__down {
    display:none;
  }
  
  





}

nav {
    background: rgba(0,0,0,0.2);
    width:-webkit-max-content;
    width:max-content;
    display: block;
    padding: 0.7rem 1.7rem;
    z-index:  2;
    position: fixed;
    left:50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    bottom: 2rem;
    display:flex;
    grid-gap: 0.8rem;
    gap: 0.8rem;
    border-radius:3rem;
    -webkit-backdrop-filter: blur(15px);
            backdrop-filter: blur(15px);
}

nav a {
    background: transparent;
    padding: 0.9rem;
    border-radius: 50%;
    display:flex;
    color: var(--color-light);
    font-size: 1.1rem;
}

nav a:hover {
    background: rgba(0,0,0,0.3);
}

nav a.active {
 background: var(--color-primary);
 color: var(--color-bg);
}



.about__container {
width:80%;
}

.about__me {
    width: 100%;
    aspect-ratio: 1/1; /*1/1 ratio of image ratio wid.length     */
    border-radius: 2rem;
    background: linear-gradient(45deg,transparent,var(--color-primary),transparent);
    display: grid;
    place-items: center; /* if you want to justify and then align items.. this is a shorthand property for that..*/
}

.about__me-image {
  border-radius: 2rem;
  overflow:hidden;/*     */
  -webkit-transform-origin: rotate(10deg);
          transform-origin: rotate(10deg);
  transition: var(--transition);
}

.about__me-image:hover {
  translate: rotate(0);
}

.about__cards {
display: grid;
grid-template-columns: repeat(3,1fr);
grid-gap: 1.5rem;
gap: 1.5rem;
}
.about__card {
    background: var(--color-primary);
    border:1px solid transparent;
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;
}

.about__card:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  cursor:default;



}

.about__icon {
    color:var(--color-primary);
    font-size: 1.4rem;
    margin-bottom: 1rem;
}

.about__card h5 {
font-size: 0.95rem;

}

.about__card small {
font-size: 0.7rem;
}
.about__content p {
margin: 2rem 0 2.6rem;
color: var(--color-light);
}


/* ******* MEDIA QUERIES(MEDIUM DEVICES) *** */
@media screen and (max-width: 1024px) {
.about__container { /* when it gets smaller , make all child blocks to this class in to a column*/
    display:grid;
    grid-template-columns: 1fr; 
    grid-gap:1.5rem; 
    gap:1.5rem;
}

.about__content {
    margin-top: 1.5rem;
}

}

/* *******MEDIA QUERIES (SMALL DEVICES **** */
@media screen and (max-width: 600px) {
    .about__container { /* when it gets smaller , make all child blocks to this class in to a column*/
       display:grid;
        grid-template-columns: 1fr; 
        grid-gap:1rem; 
        gap:1rem;
    }
    .about__me {
        width:65%;
        margin:0 auto 3rem;
    }

    .about__cards {
        grid-template-columns: 1fr 1fr;
        grid-gap:1em;
        gap:1em;
    }


    .about__content {
        text-align: center;
    }

    .about__content p {
        margin: 1.5rem 0;
    }





}






.experience__container {
    display:grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    gap: 2rem;
}

.experience__container > div {
background: var(--color-primary);
padding: 2.4rem 5rem;
border-radius: 2rem;
transition:var(--transition);
}


.experience__container > div:hover {
background:transparent;
border-color:var(--color-primary);
cursor:default;

}

.experience__container > div h3 {
text-align:center;
margin-bottom: 2rem;
color: var(--color-light);
}

.experience__content {
display: grid;
grid-template-columns: 1fr 1fr;
grid-row-gap:2rem;
row-gap:2rem;

}

.experience__details {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
}



/* ******* MEDIA QUERIES(MEDIUM DEVICES) *** */
@media screen and (max-width: 1024px) {
   .experience__container {
    grid-template-columns: 1fr;
   }

   .experience__container > div  {
    width: 80%;
    padding: 2rem;
    margin: 0 auto;
    }

    .experience__content {
        padding: 1rem;
    }


}
    
    /* *******MEDIA QUERIES (SMALL DEVICES **** */
    @media screen and (max-width: 600px) {
   .experience__container {
    grid-gap:1rem;
    gap:1rem;
   }
    
   .experience__container > div {
    width:100%;
    padding: 2rem 1rem;
   }

    }
    
    
    
    
    
    
.portfolio__container {
    display:grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap:2.5rem;
    gap:2.5rem;
}

.portfolio__items {
    background: var(--color-bg-variant);
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.portfolio__items:hover {
border-color: var(--color-bg-variant);
background: transparent;
}

.portfolio__item-image {
border-radius: 1.5rem;
overflow: hidden;
}

/* .img__description {
position:absolute;
left:0;
bottom: 0;
  right: 0;
  background: rgba(29, 106, 154, 0.72);
  color: #fff;
  visibility: hidden;
  opacity: 0;

  /* transition effect. not necessary */
  /* transition: opacity .2s, visibility .2s;
} */

/* .portfolio__item-image:hover .img__description {
    visibility: visible;
    opacity: 1;
  } */ */


.portfolio__item h3 {
margin: 1.2rem 0 2rem;
}

.portfolio__item-cta {
display:flex;
grid-gap:1rem;
gap:1rem;
margin-bottom: 1rem;
}

/* ******* MEDIA QUERIES(MEDIUM DEVICES) *** */
@media screen and (max-width: 1024px) {
    .portfolio__container { /* when it gets smaller , make all child blocks to this class in to a column*/
        grid-template-columns: 1fr 1fr; 
        grid-gap:1.2rem; 
        gap:1.2rem;
    }
    
    }
    
    /* *******MEDIA QUERIES (SMALL DEVICES **** Once it hits this width.. make it a portfolio container.. */
    @media screen and (max-width: 600px) {
        .portfolio__container {
            grid-template-columns: 1fr;
            grid-gap:1rem;
            gap:1rem;
        }
    
    
    
    
    
    }

footer {
    background: var(--color-primary);
    padding: 3rem 0;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 7rem;
    height:60vh;
}

.footer__logo {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 2rem;
    display: inline-block;
}

.permalinks {
display: flex;
flex-wrap:wrap;
justify-content: center;
grid-gap: 2rem;
gap: 2rem;
margin: 0 auto 3rem;
}

.footer__socials {
    display:flex;
    justify-content: center;
    grid-gap:1rem;
    gap:1rem;
    margin-bottom: 4rem;
}

.footer__socials a {
  background-color: var(--color-bg);
  color: var(--color-white);
  padding: 0.8rem;
  border-radius: 0.7rem;
  display:flex;
  border: 1px solid transparent;

}

.footer__socials a:hover {
color: var(--color-bg);
border-color: var(--color-bg);
}

.footer__copyright {
    margin-bottom: 4rem;
    color: var(--color-bg);
}

.footer__copyright {
    margin-bottom: 4rem;
    color: var(--color-bg);
}


/* ******* MEDIA QUERIES(MEDIUM DEVICES) *** */
@media screen and (max-width: 1024px) {
    .about__container { /* when it gets smaller , make all child blocks to this class in to a column*/
        grid-template-columns: 1fr; 
        grid-gap:0; 
        gap:0;
    }
    
    }
    
    /* *******MEDIA QUERIES (SMALL DEVICES **** */
    @media screen and (max-width: 600px) {
       
    .permalinks {
        flex-direction: column;
        grid-gap:1.5rem;
        gap:1.5rem;
    }

    .footer__socials {
        margin-bottom: 2.6rem;
    }




    }
    
    
    
.container.contact__container {
    width: 50%;
    display: grid;
    grid-template-columns: 30% 58%;
    grid-gap: 12%;
    gap: 12%;
}

.contact__option {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
    flex-direction: column;
    grid-gap: 1.2rem;
    gap: 1.2rem;
}

.contact__option:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
}

.contact__option-icon {
    font-size: 1.5rem;
    margin-bottom:0.5rem;
}

.contact__option a {
    margin-top: 0.7rem;
    display: inline-block;
    font-size:0.8rem;
}

/* **************************** FORM ***************************** */
form {
    display: flex;
    flex-direction: column;
    grid-gap:1.2rem;
    gap:1.2rem;
}

input,textarea {
    width:100%;
    padding:1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-primary-variant);
    resize:none;
    color: var(--color-white);
}




/* ******* MEDIA QUERIES(MEDIUM DEVICES) *** */
@media screen and (max-width: 1024px) {
    .container.contact__container { /* when it gets smaller , make all child blocks to this class in to a column*/
        grid-template-columns: 1fr; 
        grid-gap:2rem; 
        gap:2rem;
    }
    
    }

/* *******MEDIA QUERIES (SMALL DEVICES **** */
@media screen and (max-width: 600px) {
   .container.contact__container {
  width: var(--container-width-sm);

   }




}


.container.testimonials__container {
    width:40%;
    padding-bottom: 4rem;
}

.client__avatar {
    width: 4rem;
    aspect-ratio:1/1;
    overflow:hidden;
    border-radius: 50%;
    margin: 0 auto 1rem;
    border: 0.4rem solid var(--color-primary);
}


.testimonial {
    background:var(--color-primary);
    text-align: center;
    padding:2rem;
    border-radius:2rem;
    -webkit-user-select: none;
            user-select: none;
}

.client__review {
    color: var(--color-light);
    font-weight: 300;
    display: block;
    width:80%;
    margin: 0.8rem auto 0;
    
}


.client__name {

    font-size: 2rem;
}




/* ******* MEDIA QUERIES(MEDIUM DEVICES) *** */
@media screen and (max-width: 1024px) {
    .container.testimonials__container {
        width:60%
    }

    .client__name {
        font-size: 1.4rem;
    }
    
    
    }
/* ******* MEDIA QUERIES(SMALL DEVICES) *** */
@media screen and (max-width: 600px) {
    .container.testimonials__container {
        width: var(--container-width-sm);
    }
    
    .client__review {
        width: var(--container-width-sm);
    }


}
   
    
    




