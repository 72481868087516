.about__container {
width:80%;
}

.about__me {
    width: 100%;
    aspect-ratio: 1/1; /*1/1 ratio of image ratio wid.length     */
    border-radius: 2rem;
    background: linear-gradient(45deg,transparent,var(--color-primary),transparent);
    display: grid;
    place-items: center; /* if you want to justify and then align items.. this is a shorthand property for that..*/
}

.about__me-image {
  border-radius: 2rem;
  overflow:hidden;/*     */
  transform-origin: rotate(10deg);
  transition: var(--transition);
}

.about__me-image:hover {
  translate: rotate(0);
}

.about__cards {
display: grid;
grid-template-columns: repeat(3,1fr);
gap: 1.5rem;
}
.about__card {
    background: var(--color-primary);
    border:1px solid transparent;
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;
}

.about__card:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  cursor:default;



}

.about__icon {
    color:var(--color-primary);
    font-size: 1.4rem;
    margin-bottom: 1rem;
}

.about__card h5 {
font-size: 0.95rem;

}

.about__card small {
font-size: 0.7rem;
}
.about__content p {
margin: 2rem 0 2.6rem;
color: var(--color-light);
}


/* ******* MEDIA QUERIES(MEDIUM DEVICES) *** */
@media screen and (max-width: 1024px) {
.about__container { /* when it gets smaller , make all child blocks to this class in to a column*/
    display:grid;
    grid-template-columns: 1fr; 
    gap:1.5rem;
}

.about__content {
    margin-top: 1.5rem;
}

}

/* *******MEDIA QUERIES (SMALL DEVICES **** */
@media screen and (max-width: 600px) {
    .about__container { /* when it gets smaller , make all child blocks to this class in to a column*/
       display:grid;
        grid-template-columns: 1fr; 
        gap:1rem;
    }
    .about__me {
        width:65%;
        margin:0 auto 3rem;
    }

    .about__cards {
        grid-template-columns: 1fr 1fr;
        gap:1em;
    }


    .about__content {
        text-align: center;
    }

    .about__content p {
        margin: 1.5rem 0;
    }





}





